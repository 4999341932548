import {
  Button,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react"
import NextLink from "next/link"

import AskIcon from "@/icons/Ask"
import ChatIcon from "@/icons/Chat"
import EmailIcon from "@/icons/Email"
import VideoIcon from "@/icons/Video"
import { useDevice } from "@/ui/hooks"

export interface AskSnowdayModalProps {
  open: boolean
  onClose: () => void
}

export const AskSnowdayModal = ({ open, onClose }: AskSnowdayModalProps) => {
  const { isMobile } = useDevice()

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={{ base: "296px", md: "420px" }}>
        <ModalHeader paddingTop="40px" paddingBottom={5} color="snow.blue-dark">
          <HStack spacing={1} alignItems="center" justifyContent="center">
            <AskIcon />
            <Text fontSize="18px" fontFamily="nunito" align="center">
              Ask Snowday
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          paddingTop={0}
          px={{ base: 5, md: 10 }}
          overflowY="auto"
          paddingBottom={10}
        >
          <VStack spacing={4} alignItems="stretch">
            <Text align="center" width={{ base: "256px", md: "340px" }}>
              Get free program recommendations from an expert
            </Text>

            <VStack spacing={3}>
              <Link
                href="https://calendly.com/asksnowday/30min"
                as={NextLink}
                isExternal
                w="100%"
              >
                <Button leftIcon={<VideoIcon />}>Schedule video call</Button>
              </Link>
              {isMobile ? (
                <Link href="sms:+19292778129" as={NextLink} w="100%">
                  <Button leftIcon={<ChatIcon />} variant="secondary">
                    Send text message
                  </Button>
                </Link>
              ) : null}
              <Link
                href="mailto:ask@snow.day?subject=Ask Snowday Request"
                as={NextLink}
                w="100%"
              >
                <Button leftIcon={<EmailIcon />} variant="secondary">
                  Send email
                </Button>
              </Link>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
