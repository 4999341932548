import { Flex, Link, Tag } from "@chakra-ui/react"
import NextLink from "next/link"

import { useFilter } from "@/filters/providers/FiltersProvider"
import { FiltersType } from "@/filters/types"
import { Interest } from "@/generated/graphql"
import { SearchTypes } from "@/search/types"
import { generateResultUrl } from "@/search/utils/domain"
import { unique } from "@/utils/array"
import { truthy } from "@/utils/fp"

interface InterestsComponentProps {
  interests: Interest[]
  hasLink?: boolean
}

export const InterestsComponent = ({
  interests,
  hasLink = false,
}: InterestsComponentProps) => {
  const { appliedFilters } = useFilter()

  const interestFilters = appliedFilters[FiltersType.INTERESTS]
    ? Array.isArray(appliedFilters[FiltersType.INTERESTS])
      ? appliedFilters[FiltersType.INTERESTS]
      : [appliedFilters[FiltersType.INTERESTS]]
    : []

  const interestsToDisplay = []

  interestFilters.forEach((interestFilter) => {
    const matchingInterest = interests.find(
      (interest) => interest.name === interestFilter.name,
    )

    if (matchingInterest) {
      interestsToDisplay.push(matchingInterest)
    }
  })

  interestsToDisplay.push(...interests)

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      flexWrap={"wrap"}
      gap={{ base: 1, md: 2 }}
      py={{ base: 2, md: 3 }}
    >
      {unique(interestsToDisplay, ({ name }) => name.toLowerCase())
        .filter(truthy)
        .slice(0, 3)
        .map(({ identifier, name }) => (
          <Tag
            key={identifier}
            rounded="full"
            px={{ base: 3, md: 4 }}
            py={1}
            color="snow.blue-dark"
          >
            {hasLink ? (
              <Link
                href={generateResultUrl(
                  SearchTypes.INTERESTS,
                  name,
                  identifier,
                )}
                as={NextLink}
              >
                {name}
              </Link>
            ) : (
              name
            )}
          </Tag>
        ))}
    </Flex>
  )
}
