import {
  Box,
  Card,
  Flex,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from "@chakra-ui/react"
import NextLink from "next/link"
import { Fragment, memo } from "react"

import {
  LearningOpportunity,
  LearningOpportunityType,
  MediaType,
} from "@/generated/graphql"
import DeadlineComponent from "@/learningOpportunities/components/DeadlineComponent"
import { InterestsComponent } from "@/learningOpportunities/components/InterestsComponent"
import LocationComponent from "@/learningOpportunities/components/LocationComponent"
import SessionDatesComponent from "@/learningOpportunities/components/SessionDatesComponent"
import { FinancialAccessibilityBadge } from "@/ui/badges/FinancialAccessibilityBadge"
import { LOTypeBadge } from "@/ui/badges/LOTypeBadge"
import { SelectiveBadge } from "@/ui/badges/SelectiveBadge"
import { breakpoints, useWindowSize } from "@/ui/hooks"

export type PartialLearningOpportunity = Pick<
  LearningOpportunity,
  | "type"
  | "deadlines"
  | "media"
  | "financialAccessibility"
  | "identifier"
  | "interests"
  | "name"
  | "provider"
  | "selective"
  | "sessions"
>

interface LOResultCardProps {
  learningOpportunity: PartialLearningOpportunity
  noninteractive?: boolean
  compact?: boolean
  children?: React.ReactNode
}

export default memo(function LOResultCard({
  learningOpportunity,
  noninteractive,
  compact,
  children,
}: LOResultCardProps) {
  const { width } = useWindowSize()

  const {
    type,
    deadlines,
    financialAccessibility,
    identifier,
    interests,
    media,
    name,
    provider,
    selective,
    sessions,
  } = learningOpportunity

  const firstImage = media.find(({ type }) => type === MediaType.Image)

  const iconsFlexDirection =
    compact || (width && width <= breakpoints.md) ? "column" : "row"

  const LinkBoxComponent = noninteractive ? Fragment : LinkBox
  const LinkOverlayComponent = noninteractive ? Fragment : LinkOverlay

  return (
    <Card w="100%" padding={4}>
      <LinkBoxComponent>
        <LinkOverlayComponent
          as={NextLink}
          href={`/learning-opportunities/${identifier}`}
        >
          <Flex gap="10px" position="relative">
            <Image
              alt={name}
              src={firstImage?.uri || ""}
              objectFit="contain"
              boxSize={{ base: 14, md: 20 }}
              backgroundColor="snow.blue-dark"
              rounded={4}
            />
            <Box>
              <Box mr={{ base: 7, md: 0 }} mb={{ base: 1, md: 0 }}>
                <Box>
                  <Heading
                    variant="h3"
                    as="h3"
                    lineHeight="20px"
                    color="snow.blue-dark"
                    mb={0.5}
                  >
                    {name}
                  </Heading>
                  <Text color="snow.blue-dark" lineHeight="18px">
                    {provider?.name}
                  </Text>
                </Box>
                <InterestsComponent interests={interests} />
              </Box>
              <Flex gap={2} flexWrap={"wrap"} mb={2}>
                <SessionDatesComponent sessions={sessions} />
              </Flex>
              <Flex gap={2} flexWrap={"wrap"} mb={2}>
                <LocationComponent sessions={sessions} />
              </Flex>
              <DeadlineComponent deadlines={deadlines} />
            </Box>
            <VStack
              marginLeft="auto"
              position={{ base: "absolute", md: "relative" }}
              top={0}
              right={0}
            >
              <Flex
                flexDirection={iconsFlexDirection}
                alignItems="center"
                justifyContent={"flex-start"}
                gap={1}
              >
                <LOTypeBadge type={type as LearningOpportunityType} />
                <FinancialAccessibilityBadge
                  accessibility={financialAccessibility}
                  includeLabelIcon
                />
                <SelectiveBadge selective={selective} />
              </Flex>
            </VStack>
          </Flex>
        </LinkOverlayComponent>
      </LinkBoxComponent>
      {children}
    </Card>
  )
})
