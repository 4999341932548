import { Flex, Text } from "@chakra-ui/react"

import { Deadline, Maybe } from "@/generated/graphql"
import SnowdayIcons from "@/icons/SnowdayIcons"
import { PredictedDeadlineBadge } from "@/ui/badges/PredictedBadge"
import {
  getNextDeadline,
  getSummarizedTextFromLODeadlines,
} from "@/utils/domain"

interface LocationProps {
  deadlines?: Maybe<Maybe<Deadline>[]>
}

export default function DeadlineComponent({ deadlines = [] }: LocationProps) {
  const deadlineValue = getSummarizedTextFromLODeadlines(deadlines ?? [])
  const nextDeadline = getNextDeadline(deadlines ?? [])

  return deadlineValue ? (
    <Flex flexDirection="row" alignItems="center" gap={1}>
      <SnowdayIcons name="Alarm" boxSize={{ base: 4, md: 5 }} />
      <Text
        color={deadlineValue.color}
        fontSize={{ base: "15px", md: "16px" }}
        fontStyle="italic"
        lineHeight={{ base: "15px", md: "16px" }}
        fontWeight={700}
      >
        Deadline:
      </Text>
      <Text
        fontSize={{ base: "15px", md: "16px" }}
        color={deadlineValue.color}
        lineHeight={{ base: "15px", md: "16px" }}
      >
        {deadlineValue.text}
      </Text>
      <PredictedDeadlineBadge predicted={nextDeadline?.predicted ?? false} />
    </Flex>
  ) : null
}
