import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { Fragment, ReactElement, ReactNode, useMemo } from "react"

import { sort, Sortable } from "@/utils/sorting"

interface BadgeModalButtonProps {
  onClick: () => void
  children: ReactNode
}

/**
 * This is a custom button to escape the link overlay
 *
 * @see https://github.com/chakra-ui/chakra-ui/issues/5673
 */
export const BadgeModalButton = ({
  children,
  onClick,
}: BadgeModalButtonProps) => (
  <Flex
    gap={1}
    as="button"
    cursor="pointer"
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
    style={{ zIndex: 2, margin: 0 }}
    alignItems="center"
  >
    {children}
  </Flex>
)

export interface BadgeModalProps {
  open: boolean
  onClose: () => void
}

type InnerModalProps<T extends string | number> = BadgeModalProps & {
  sortMap: Record<T, Sortable>
  title: string
  values: Record<string, T>
  renderItem: (entry: T) => ReactElement
}

export const BadgeModal = <T extends string | number>({
  open,
  onClose,
  title,
  values,
  renderItem,
  sortMap,
}: InnerModalProps<T>) => {
  const sortedValues = useMemo(
    () => sort(Object.entries(values), ([_, value]) => sortMap[value] ?? 0),
    [sort, values],
  )

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        height={{ base: "520px", md: "560px", lg: "480px", xl: "520px" }}
      >
        <ModalHeader paddingTop="40px" paddingBottom={5}>
          <Text fontSize="18px" fontFamily="nunito" align="center">
            {title}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingTop={0} overflowY="auto" paddingBottom="20px">
          <Flex
            flexDirection="column"
            gap="1px"
            maxHeight="100%"
            overflow="auto"
            className="scrollbar"
          >
            {sortedValues.map(([key, value]) => (
              <Fragment key={key}>{renderItem(value)}</Fragment>
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
