import { Box, Flex, Text, useBoolean } from "@chakra-ui/react"
import { forwardRef, useImperativeHandle } from "react"

import { BadgeModal, BadgeModalButton, BadgeModalProps } from "./BadgeModal"
import CompetitionIcon from "../icons/Competition"
import GraduationCapIcon from "../icons/GraduationCap"
import SunIcon from "../icons/Sun"
import { loTypeSortMap } from "@/features/utils"
import { LearningOpportunityType } from "@/generated/graphql"

const content: Record<
  LearningOpportunityType,
  { label: string; Icon: () => JSX.Element; description?: string }
> = {
  [LearningOpportunityType.SchoolYearProgram]: {
    label: "School Year Program",
    Icon: () => <GraduationCapIcon boxSize={6} />,
    description:
      "An extracurricular activity typically held after school on weekdays or on weekends.",
  },
  [LearningOpportunityType.SummerProgram]: {
    label: "Summer Program",
    Icon: () => <SunIcon boxSize={6} />,
    description:
      "Educational and recreational programming offered during the summer.",
  },
  [LearningOpportunityType.Competition]: {
    label: "Competition",
    Icon: () => <CompetitionIcon boxSize={5} />,
    description:
      "An extracurricular activity held year-round where participants or teams compete, with a few winners or top performers recognized with awards.",
  },
}

interface LoTypeItemProps {
  type: LearningOpportunityType
}

const LoTypeItem = ({ type }: LoTypeItemProps) => {
  return (
    <Box paddingTop="20px" background="white">
      <Flex gap="4px" alignItems="center">
        {content[type]?.Icon()}
        <Text fontSize={18} fontFamily="nunito" fontWeight="bold">
          {content[type]?.label}
        </Text>
      </Flex>
      {content[type]?.description && (
        <Box margin="12px 0">
          <Text fontSize={18}>{content[type].description}</Text>
        </Box>
      )}
    </Box>
  )
}

export const LoTypeModal = ({ open, onClose }: BadgeModalProps) => (
  <BadgeModal
    sortMap={loTypeSortMap}
    title="Learning Opportunity Type"
    values={LearningOpportunityType}
    open={open}
    onClose={onClose}
    renderItem={(value) => <LoTypeItem type={value} />}
  />
)

interface Props {
  type: LearningOpportunityType
  sizeAdjust?: number
}

export const LOTypeBadge = forwardRef(({ type, sizeAdjust }: Props, ref) => {
  const adjustment = sizeAdjust ?? 0

  const [open, { toggle, off }] = useBoolean()

  useImperativeHandle(ref, () => ({
    toggleModal() {
      toggle()
    },
  }))

  const renderIcon = () => {
    if (type === LearningOpportunityType.Competition) {
      return <CompetitionIcon boxSize={5 + adjustment} mx={0.5} />
    } else if (type === LearningOpportunityType.SummerProgram) {
      return <SunIcon boxSize={6 + adjustment} />
    } else if (type === LearningOpportunityType.SchoolYearProgram) {
      return <GraduationCapIcon boxSize={6 + adjustment} />
    }

    return null
  }

  return (
    <>
      <BadgeModalButton onClick={toggle}>{renderIcon()}</BadgeModalButton>
      <LoTypeModal open={open} onClose={off} />
    </>
  )
})
