import { Box, Flex, Text } from "@chakra-ui/react"

import { Session } from "@/generated/graphql"
import SnowdayIcons from "@/icons/SnowdayIcons"
import { getLocationFromSessions } from "@/utils/domain"

interface LocationProps {
  sessions: Session[]
}

export default function LocationComponent({ sessions }: LocationProps) {
  return sessions.length ? (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent={"flex-start"}
      gap={1}
    >
      <Box h="100%" display="flex" alignItems="flex-start">
        <SnowdayIcons name="Location" boxSize={{ base: 4, md: 5 }} />
      </Box>

      <Text
        color="snow.blue-dark"
        fontSize={{ base: "15px", md: "16px" }}
        lineHeight={{ base: "15px", md: "16px" }}
      >
        {getLocationFromSessions(sessions)}
      </Text>
    </Flex>
  ) : null
}
