import { SVGProps } from "react"

const BgBottom2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 834 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={834}
      height={75}
    >
      <path fill="#D9D9D9" d="M0 0h834v75H0z" />
    </mask>
    <g>
      <path
        d="M0-441.881c167.476 45.101 317.335-40.033 483.846-20.016C650.357-441.881 692.105-370.249 834-396.78V48.465c-179.541 59.33-294.168-10.754-452.715-22.912C222.738 13.395 83.738 83.918 0 6.983v-448.864Z"
        fill="#E6F1FB"
      />
    </g>
  </svg>
)

export default BgBottom2
